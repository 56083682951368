<template>
	<div class="m-2 ">
		<ul v-if="accounts" class="scrolling-touch overflow-auto">
			<li v-for="account in accounts" :key="account.id" class="border-b border-gray-200">
				<router-link
					class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
					:to="{ name: 'dashboard', params: { account: account._name } }"
				>
					<div class="px-4 py-4 flex items-center sm:px-6">
						<div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
							<div>
								<div class="text-sm leading-5 font-medium text-indigo-600 truncate">
									{{ account.name }}
								</div>
							</div>
						</div>
						<div class="ml-5 flex-shrink-0">
							<svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
								<path
									fill-rule="evenodd"
									d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</div>
					</div>
				</router-link>
			</li>
		</ul>
		<div v-else>
			<p class="m-5 text-base leading-6 ">
				You need to
				<router-link class="text-indigo-600" :to="{ name: 'account.create' }">
					create an account
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'AccountSwitch',
	computed: {
		...mapState({
			accounts: (state) => state.auth.user.accounts
		})
	},
	metaInfo() {
		return {
			title: 'Switch Account'
		}
	}
}
</script>
